import React, { PureComponent } from "react";
import { AppContext } from "./AppContext";

interface State {
  command: string;
}

export default class extends PureComponent<AppContext, State> {
  state = {
    command: ''
  };

  onKeyPress(ev: React.KeyboardEvent<HTMLTextAreaElement>) {
    if (ev.key !== 'Enter') {
      return;
    }

    this.props.events.emit(
      'command',
      (ev.target as HTMLTextAreaElement).value);

    this.setState({command: ''});
  }

  onChange(ev: React.ChangeEvent<HTMLTextAreaElement>) {
    this.setState({command: ev.target.value});
  }

  render() {
    return <div className='input'>
      <textarea
        autoFocus={true}
        value={this.state.command}
        className='input-entry'
        onChange={this.onChange.bind(this)}
        onKeyPress={this.onKeyPress.bind(this)}/>
    </div>
  }
}

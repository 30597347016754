import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { EventEmitter } from 'events';
import Client from './client';

const events = new EventEmitter();
const client = new Client({events});

const context = {
  events,
  client
};

ReactDOM.render(<App {...context}/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

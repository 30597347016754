import React, { PureComponent } from 'react';
import './styles/index.scss';
import Channels from './Channels';
import Display from './Display';
import Users from './Users';
import Input from './Input';
import { AppContext } from './AppContext';

class App extends PureComponent<AppContext> {
  render() {
    return <div className='container'>
      <div className='chat-container'>
        <Users></Users>
        <Display {...this.props}></Display>
      </div>
      <div className='input'>
        <Input {...this.props}></Input>
      </div>
    </div>
  }
}

export default App;

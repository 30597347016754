// import WebSocket from 'ws';
import { EventEmitter } from 'events';
// import {HushMessageType, HushMessage, BroadcastMessage} from './api';

export interface ClientOptions {
  wsUrl?: string
  url?: string
  events: EventEmitter
}

export default class {
  readonly options: ClientOptions
//   wsClient: WsClient | undefined

  constructor(options: ClientOptions) {
    this.options = options;

//     this.options.wsUrl = this.options.wsUrl || 'ws://127.0.0.1:9000';
//     this.options.url = this.options.url || 'http://127.0.0.1:9001';

//     this.setupWsClient();
  }

//   private setupWsClient() {
//     this.wsClient = new WsClient(this.options);
//     this.wsClient.on('close', this.onClose.bind(this));
//   }

//   private onClose() {
//     this.wsClient!.off('close', this.onClose.bind(this));

//     setTimeout(() => {
//       this.setupWsClient();
//     }, 1000);
//   }

//   close() {
//     if (this.wsClient) {
//       this.wsClient.close();
//     }
//   }
}

// class WsClient extends EventEmitter {
//   readonly client: WebSocket
//   readonly wsUrl: string

//   constructor({wsUrl}: ClientOptions) {
//     super();

//     this.client = new WebSocket(wsUrl!);
//     this.wsUrl = wsUrl!;

//     this.on('line', this.onLine.bind(this));

//     this.client.on('open', this.onOpen.bind(this));
//     this.client.on('error', this.onError.bind(this));
//     this.client.on('message', this.onMessage.bind(this));
//     this.client.on('close', this.onClose.bind(this));
//   }

//   private onClose() {
//     this.off('line', this.onLine.bind(this));

//     this.client.off('open', this.onOpen.bind(this));
//     this.client.off('error', this.onError.bind(this));
//     this.client.off('message', this.onMessage.bind(this));
//     this.client.off('close', this.onClose.bind(this));

//     this.emit('close', {url: URL});
//   }

//   private onMessage(data: WebSocket.Data) {
//     const message = JSON.parse(data.toString()) as HushMessage;
//     switch (message.type) {
//       case HushMessageType.BROADCAST: {
//         this.emit('broadcast', message.message);
//         break;
//       }
//       case HushMessageType.AUTH_RESPONSE: {
//         this.emit('auth-response', message.message);
//         break;
//       }
//       default: {
//         // whaa
//       }
//     }
//   }

//   private onError() {
//     this.emit('error');
//   }

//   private onOpen() {
//     this.emit('open', {url: URL});
//     // this.client.send(JSON.stringify({
//     //   type: HushMessageType.AUTH,
//     //   message: {
//     //     username: this.username,
//     //     namespace: this.namespace,
//     //     channels: ['general']
//     //   }
//     // }));
//   }

//   private onLine(line: string) {
//     if (!this.isOpen()) {
//       return;
//     }

//     this.client.send(JSON.stringify({
//       type: HushMessageType.BROADCAST,
//       message: {
//         text: line,
//         channel: 'general'
//       } as BroadcastMessage
//     }));
//   }

//   isOpen() {
//     return this.client.readyState === WebSocket.OPEN;
//   }

//   close() {
//     this.client.close();
//   }
// }

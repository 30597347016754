import React from "react";
import { AppContext } from "./AppContext";
import { Component } from "react";

interface State {
  lines: string[]
}

export default class extends Component<AppContext, State> {
  el: HTMLDivElement | null

  state = {
    lines: []
  }

  constructor(props: AppContext) {
    super(props);

    this.el = null;
    this.props.events.on('command', this.onLine.bind(this));
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  scrollToBottom() {
    if (!this.el) {
      return;
    }

    this.el.scrollTop = this.el.scrollHeight;
  }

  onLine(line: string) {
    this.setState({
      lines: [line, ...this.state.lines]
    });
  }

  render() {
    const items = this.state.lines.map((line, index) => {
      return <div className='message' style={{order: index}}>{line}</div>;
    })

    return <div ref={el => this.el = el} className='display'>
      {items}
    </div>
  }
}
